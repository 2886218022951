<!--
 * @Author: wh
 * @Date: 2020-08-03 19:16:58
 * @LastEditTime: 2022-01-11 18:35:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\salesBusiness\contractArchives\history.vue
-->
<template>
    <div class="contractArchives">
        <header>
            <div class="item item-border">
                <span class="circle">
                    <span class="iconfont iconhetong2"></span>
                </span>
                <span @click="openProcessManagement" class="contractNo">合同编号：{{ contractInfo.contract_number }}</span>
                <!-- <el-button @click="openHistoricalContract" v-if="old_contract_number != ''" type="primary">
                    查看历史信息
                </el-button> -->
            </div>
            <div class="item item-border">
                <span class="circle">
                    <span class="iconfont iconqiye"></span>
                </span>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="contractInfo.client_name"
                    placement="bottom"
                >
                    <span>客户名称：{{ contractInfo.client_name }}</span>
                </el-tooltip>
            </div>
            <div class="item">
                <span class="circle">
                    <span class="iconfont icongongdi"></span>
                </span>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="contractInfo.engineering_name"
                    placement="bottom"
                >
                    <span>工程名称：{{ contractInfo.engineering_name }}</span>
                </el-tooltip>
            </div>
        </header>
        <div class="pay-mode">
            <div class="left">
                付<br>款<br>方<br>式
            </div>
            <div class="right">
                <div class="top">
                    <!-- <el-tooltip
                        class="item"
                        effect="dark"
                        :content="contractInfo.settlement_mode_and_payment_clause"
                        placement="bottom"
                    >
                        {{ contractInfo.settlement_mode_and_payment_clause }}
                    </el-tooltip> -->
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="contractInfo.settlement_mode_and_payment_clause"
                        placement="bottom"
                    >
                        <span>{{ contractInfo.settlement_mode_and_payment_clause }}</span>
                    </el-tooltip>
                </div>
                <div :class="['bottom',contractInfo.contract_price_versions&&contractInfo.contract_price_versions.price_mode==1?'mode2':'']">
                    <div class="type-name" v-if="contractInfo.contract_price_versions&&contractInfo.contract_price_versions.price_mode==0">
                        企业固定价
                    </div>
                    <div class="type-name" v-if="contractInfo.contract_price_versions&&contractInfo.contract_price_versions.price_mode==1">
                        市场信息价
                    </div>
                    <div class="detail">
                        <div class="bottom-item">
                            <span class="pre">标号</span>
                            <span class="content">
                                <span :title="markNumStr">
                                    <span
                                        class="item"
                                        v-for="(item, index) in contractInfo.contract_price_mark_num_list"
                                        :key="`mark_${index}_${item.product_mark_code}`"
                                    >
                                        {{ item.product_mark_num }}:
                                        <span class="blue">{{ item.product_mark_price }}</span>
                                        ;
                                    </span>
                                </span>

                                <!-- <span class="item">{{ markNumStr }}</span> -->

                            </span>
                        </div>
                        <div class="bottom-item">
                            <span class="pre">附加型号</span>
                            <span class="content">
                                <span :title="additionalListStr">
                                    <span
                                        class="item"
                                        v-for="(item, index) in contractInfo.contract_price_additional_list"
                                        :key="`additional_${index}_${item.additional_model_code}`"
                                    >
                                        {{ item.additional_model }}:
                                        <span class="blue">{{ item.additional_model_price }}</span>
                                        ;
                                    </span>
                                </span>

                                <!-- <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="additionalListStr"
                                        placement="bottom"
                                    >
                                        <template v-for="(item, index) in contractInfo.contract_price_additional_list">
                                            <span
                                                class="item"
                                                :key="`additional_${index}_${item.additional_model_code}`"
                                            >
                                                {{ item.additional_model }}:
                                                <span class="blue">{{ item.additional_model_price }}</span>
                                                ;
                                            </span>
                                        </template>
                                    </el-tooltip> -->

                            </span>
                        </div>
                        <div class="bottom-item" v-if="contractInfo.contract_price_versions">
                            <span class="pre">其他</span>
                            <span class="content">
                                地泵费<span class="blue">{{ contractInfo.contract_price_versions.ground_pump_price }}</span>元/方，
                                汽车泵费<span class="blue">{{ contractInfo.contract_price_versions.pump_price }}</span>元/方。
                                <span class="item" v-if="contractInfo.contract_price_versions.pump_charge_way===1">
                                    单次要货不足<span class="blue">{{ contractInfo.contract_price_versions.pump_min }}</span>方，
                                    按<span class="blue">{{ contractInfo.contract_price_versions.pump_add }}</span>方结算。
                                </span>
                                <span class="item" v-if="contractInfo.contract_price_versions.pump_charge_way===2">
                                    单次要货不足<span class="blue">{{ contractInfo.contract_price_versions.pump_volumn_less }}</span>方，
                                    收取<span class="blue">{{ contractInfo.contract_price_versions.pump_plus_money }}</span>元。
                                </span>
                                <span v-if="contractInfo.contract_price_versions.pumper_money_mode===1">
                                    单次泵送不足<span class="blue">{{ contractInfo.contract_price_versions.pumper_volumn_min }}</span>方，
                                    收出场费<span class="blue">{{ contractInfo.contract_price_versions.pumper_add_money }}</span>元
                                </span>
                            </span>
                        </div>
                        <div class="bottom-item more" @click="viewHistory" v-if="contractInfo.price_type != 0">
                            历史调价记录>>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="payed" id="payed">
            <div class="item item1">
                已付款:{{ payment.total_payment }}
            </div>
            <div class="item-con">
                <div class="warpper clearfix">
                    <div
                        class="item item-bg"
                        :class="payClass(index)"
                        v-for="(item, index) in payment.payment_list"
                        :key="`pay_${index}`"
                    >
                        <div class="num">
                            {{ item.payment }}
                        </div>
                        <div class="date">
                            {{ item.receipt_date }}
                        </div>
                    </div>
                </div>
                <div class="btn-con" v-if="payment.payment_list">
                    <div class="custom-btn left-btn" @click="handlePayleft">
                        <span class="iconfont iconicon-up"></span>
                    </div>
                    <div class="custom-btn right-btn" @click="handlePayright">
                        <span class="iconfont iconicon-up"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="payed refund-records" id="refund-records">
            <div class="item item1">
                退款记录:{{ refund.total_payment }}
            </div>
            <div class="item-con">
                <div class="warpper clearfix">
                    <div
                        class="item item-bg"
                        :class="payClass(index)"
                        v-for="(item, index) in refund.payment_list"
                        :key="`pay_${index}`"
                    >
                        <div class="num">
                            {{ item.payment }}
                        </div>
                        <div class="date">
                            {{ item.receipt_date }}
                        </div>
                    </div>
                </div>
                <div class="btn-con" v-if="payment.payment_list">
                    <div class="custom-btn left-btn" @click="handleTotalPayleft">
                        <span class="iconfont iconicon-up"></span>
                    </div>
                    <div class="custom-btn right-btn" @click="handleTotalPayright">
                        <span class="iconfont iconicon-up"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="deliveries-case">
            <div class="hearder">
                <div class="tit">
                    发货情况
                </div>
                <div class="fl describe">
                    <span class="enable_time" v-if="old_contract_number">合同转换启用时间：{{ contractInfo.enable_time }}</span>
                    <span>首次供应时间：{{ deliveryData.first_goods_time }}</span>
                    <span>最后一次供货时间：{{ deliveryData.latest_supply_time }}</span>
                    <span>最后一次付款时间：{{ deliveryData.latest_payment_time }}</span>
                    <span>合同预计方量：{{ deliveryData.projected_capacity }}</span>
                </div>
            </div>
            <table class="custom-table">
                <tbody>
                    <tr>
                        <td class="td-tit">
                            销售方量(m³)
                        </td>
                        <td>{{ deliveryData.xsfl }}</td>
                        <td class="td-tit">
                            结算方量(m³)
                        </td>
                        <td>{{ deliveryData.jsfl }}</td>
                        <td class="td-tit">
                            签收方量(m³)
                        </td>
                        <td>{{ deliveryData.qsfl }}</td>
                        <td class="td-tit">
                            发货方量(m³)
                        </td>
                        <td>{{ deliveryData.fhfl }}</td>
                        <td class="td-tit">
                            生产方量(m³)
                        </td>
                        <td>{{ deliveryData.scfl }}</td>
                    </tr>
                    <tr>
                        <td class="td-tit">
                            调度派单量(m³)
                        </td>
                        <td>{{ deliveryData.ddpdl }}</td>
                        <td class="td-tit">
                            出场过磅量(m³)
                        </td>
                        <td>{{ deliveryData.ccgbl }}</td>
                        <td class="td-tit">
                            调度完成方量(m³)
                        </td>
                        <td>{{ deliveryData.ddwcfl }}</td>
                        <td class="td-tit">
                            剩退灰量(m³)
                        </td>
                        <td>{{ deliveryData.ttfl }}</td>
                        <td class="td-tit">
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="date-picker">
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="selectList"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-input
                v-model="searchVal"
                placeholder="请输入查询条件"
                class="search-input"
                @change="handleSearch"
            ></el-input>
            <span class="sum-span">发货应收账款金额：{{ receivable_amount_total }}</span>
            <span class="sum-span">应收账款余额：{{ accounts_receivable_balance }}</span>
            <span class="sum-span">补录应收账款：{{ supplement_accounts_receivable }}</span>
            <span class="export_btn" @click="exportData">
                <i class="iconfont icondaochu"></i>
                <span>导出</span>
            </span>
        </div>
        <div class="table-bg">
            <el-table
                :data="tableData"
                style="width: 100%"
                stripe
            >
                <el-table-column
                    prop="creation_date"
                    label="日期"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="task_number"
                    label="任务单号"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="station_name"
                    label="供应场站"
                >
                </el-table-column>
                <el-table-column
                    label="施工部位"
                >
                    <template slot-scope="scope">
                        <span :title="scope.row.place_detail">{{ scope.row.place_detail }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="砼品种"
                >
                    <template slot-scope="scope">
                        <span :title="scope.row.concrete_varieties">{{ scope.row.concrete_varieties }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_sign_volume"
                >
                    <template slot="header">
                        <span class="green">签收方量</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="green">{{ scope.row.adjusted_sign_volume }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_sign_volume"
                >
                    <template slot="header">
                        <span class="green">砼方量</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="green">{{ scope.row.adjusted_concrete_volume }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_concrete_price"
                >
                    <template slot="header">
                        <span class="green">砼单价</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="green">{{ scope.row.adjusted_concrete_price }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_concrete_amount_money"
                >
                    <template slot="header">
                        <span class="green">砼金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="green">{{ scope.row.adjusted_concrete_amount_money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_volume"
                >
                    <template slot="header">
                        <span class="blue">砂浆方量</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.adjusted_mortar_volume }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_volume"
                >
                    <template slot="header">
                        <span class="blue">砂浆单价</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.adjusted_mortar_price }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_volume"
                >
                    <template slot="header">
                        <span class="blue">砂浆金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.adjusted_mortar_amount_money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_volume"
                >
                    <template slot="header">
                        <span class="blue">泵送方量</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.adjusted_pump_volume }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_money"
                >
                    <template slot="header">
                        <span class="blue">泵送金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.adjusted_pump_money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_freight"
                >
                    <template slot="header">
                        <span class="purple">运费</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="purple">{{ scope.row.adjusted_freight }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="replenish_quantity"
                >
                    <template slot="header">
                        <span class="purple">补方数</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="purple">{{ scope.row.replenish_quantity }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_replenish_quantity"
                    width="120"
                >
                    <template slot="header">
                        <span class="purple">调整后补方数</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="purple">{{ scope.row.adjusted_replenish_quantity }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="replenish_money"
                >
                    <template slot="header">
                        <span class="purple">补方金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="purple">{{ scope.row.replenish_money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="adjusted_replenish_money"
                    width="120"
                >
                    <template slot="header">
                        <span class="purple">调整后补方金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="purple">{{ scope.row.adjusted_replenish_money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="other_amounts"
                >
                    <template slot="header">
                        <span class="account">其他金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="account">{{ scope.row.other_amounts }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="receivable_amount"
                >
                    <template slot="header">
                        <span class="account">应收金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="account">{{ scope.row.receivable_amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="accumulated_amount"
                    width="120"
                >
                    <template slot="header">
                        <span class="total">累计金额</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="total" :title="scope.row.accumulated_amount">{{ scope.row.accumulated_amount }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 时间范围
            dateRange: [],
            tableData: [],
            currentPage: 1,
            pagesize: 10,
            total: 0,
            // 合同编号
            contract_number: '',
            // 合同档案信息
            contractInfo: {
                contract_price_versions: {
                    compensate_charge_way: 1,
                },
            },
            // 收款记录
            payment: {},
            // 发货情况
            deliveryData: {},
            markNumStr: '',
            additionalListStr: '',
            payedConWidth: 0,
            payedWrapperWith: 0,
            payedLeft: 0,
            // 退款记录
            refund: {},
            totalPayedLeft: 0,
            // 查询条件
            searchVal: '',
            // 应收金额合计
            receivable_amount_total: 0,
            // 应收账款余额
            accounts_receivable_balance: 0,
            // 补录应收账款
            supplement_accounts_receivable: 0,
            // 历史合同编号
            old_contract_number: '',
            // 流程实例id
            business_instance_id: '',
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.contract_number = this.extr.contract_number;
        this.initTime();
        this.getContractInfo();
        this.getAccounts();
        this.handleRefund();
        this.getDailytask();
        this.getAccountList();
    },
    methods: {
        // 初始化时间范围为当前月
        initTime() {
            const endTime = util.currentTimeFormat('yyyy-MM-dd');
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const startTime = year + '-' + month + '-01';
            this.dateRange.push(startTime);
            this.dateRange.push(endTime);
        },
        getPayedLeft() {
            this.payedConWidth = this.$('#payed .item-con').width();
            this.payedWrapperWith = this.$('#payed .warpper').width();
            if (this.payedWrapperWith > this.payedConWidth) {
                this.payedLeft = -(this.payedWrapperWith - this.payedConWidth + 29);
            }
        },
        getTotalPayedLeft() {
            const payedConWidth = this.$('#refund-records .item-con').width();
            const payedWrapperWith = this.$('#refund-records .warpper').width();
            if (payedWrapperWith > payedConWidth) {
                this.totalPayedLeft = -(payedWrapperWith - payedConWidth + 29);
            }
        },
        handlePayleft() {
            this.$('#payed .warpper').css('left', 0);
        },
        handlePayright() {
            this.$('#payed .warpper').css('left', this.payedLeft);
        },
        handleTotalPayleft() {
            this.$('#refund-records .warpper').css('left', 0);
        },
        handleTotalPayright() {
            this.$('#refund-records .warpper').css('left', this.totalPayedLeft);
        },
        exportData() {
            const url = '/interfaceApi/sale/contract_archives/export_history_daily_task?keyword=' + this.searchVal + '&begin_time='
            + this.dateRange[0] + '&end_time=' + this.dateRange[1] + '&contract_number=' + this.contract_number;
            this.$axios.get(url, { responseType: 'arraybuffer' })
                .then(res => {
                    const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
                    const fileName = '导出数据.xls';
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                }).catch(() => {
                    this.$message.error('系统异常');
                });
        },
        // 模糊搜索
        handleSearch() {
            this.currentPage = 1;
            this.getAccountList();
        },
        // 历史调价记录
        viewHistory() {
            const price_type = this.contractInfo.price_type;
            let tableCode = '';
            if (price_type === 1) {
                tableCode = 'contract_price_record';
            } else if (price_type === 2) {
                tableCode = 'contract_price_record_xxj';
            }
            this.$toast(
                {
                    title: true,
                    type: 'eject',
                    width: '13.4rpm',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: tableCode,
                            QueryString: 'contract_number=' + this.contract_number,
                        },
                    },
                }
            );
        },
        // 获取合同信息
        async getContractInfo() {
            const res = await this.getContractInfoApi();
            if (res) {
                this.contractInfo = res;
                if (res.contract_price_mark_num_list) {
                    res.contract_price_mark_num_list.map(item => {
                        this.markNumStr += item.product_mark_num + ':' + item.product_mark_price + '；';
                    });
                }
                if (res.contract_price_additional_list) {
                    res.contract_price_additional_list.map(item => {
                        this.additionalListStr += item.additional_model + ':' + item.additional_model_price + '；';
                    });
                }
                if (res.old_contract_number) {
                    this.old_contract_number = res.old_contract_number;
                }
                if (res.business_instance_id) {
                    // 流程实例id
                    this.business_instance_id = res.business_instance_id;
                }
            }
        },
        // 获取收款记录
        async getAccounts() {
            const res = await this.getAccountsApi();
            if (res) {
                if (JSON.stringify(res) !== '{}') {
                    res.payment_list.map(item => {
                        if (item.receipt_date) {
                            item.receipt_date = item.receipt_date.substr(0, 10);
                        }
                    });
                    this.payment = res;
                    setTimeout( () => {
                        this.getPayedLeft();
                    }, 200);
                }
            }
        },
        // 获取收款记录
        async handleRefund() {
            const res = await this.getRefund();
            if (res) {
                if (JSON.stringify(res) !== '{}') {
                    res.payment_list.map(item => {
                        if (item.receipt_date) {
                            item.receipt_date = item.receipt_date.substr(0, 10);
                        }
                    });
                    this.refund = res;
                    setTimeout( () => {
                        this.getTotalPayedLeft();
                    }, 200);

                }
            }
        },
        // 获取发货情况
        async getDailytask() {
            const res = await this.getDailytaskApi();
            if (res) {
                this.deliveryData = res;
            }
        },
        // 获取分页任务信息
        async getAccountList() {
            const res = await this.getAccountListApi();
            if (res) {
                this.tableData = res.list.rows;
                this.total = res.list.total;
                this.receivable_amount_total = res.receivable_amount_total;
                this.accounts_receivable_balance = res.accounts_receivable_balance;
                this.supplement_accounts_receivable = res.supplement_accounts_receivable;
            }
        },
        // 按时间范围拉取
        selectList() {
            this.currentPage = 1;
            this.getAccountList();
        },
        // 收款记录class
        payClass(index) {
            const str = 'item-bg' + index % 2;
            return str;
        },
        // 改变每页条数
        handleSizeChange(size) {
            this.pagesize = size;
            this.getAccountList();
        },
        // 切换页码
        handleCurrentChange(cur) {
            this.currentPage = cur;
            this.getAccountList();
        },
        // 根据合同编号获取档案信息
        getContractInfoApi() {
            const path = '/interfaceApi/sale/contract_archives/get_history_contract_basic_info/' + this.contract_number;
            return this.$axios.$get(path, { defEx: true });
        },
        // 根据合同编号获取收款记录
        getAccountsApi() {
            const path = '/interfaceApi/sale/contract_archives/get_history_accounts_proceeds_list/' + this.contract_number;
            return this.$axios.$get(path, { defEx: true });
        },
        // 根据合同编号获取退款记录
        getRefund() {
            const path = '/interfaceApi/sale/contract_archives/get_history_accounts_refund_list/' + this.contract_number;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取生产发货统计数据
        getDailytaskApi() {
            const path = '/interfaceApi/sale/contract_archives/get_history_delivery_statistics/' + this.contract_number;
            return this.$axios.$get(path, { defEx: true });
        },
        // 分页拉取任务信息
        getAccountListApi() {
            const queryStr = '?keyword=' + this.searchVal + '&begin_time=' + this.dateRange[0] + '&end_time='
            + this.dateRange[1] + '&contract_number=' + this.contract_number + '&pageindex=' + this.currentPage + '&pagesize=' + this.pagesize;
            const path = '/interfaceApi/sale/contract_archives/get_history_accounts_dailytask_list' + queryStr;
            return this.$axios.$get(path, { defEx: true });
        },
        // 点合同编号，打开流程办理
        openProcessManagement() {
            this.$WFShowSceneLayer(this.business_instance_id, 'view');
        },
        // 查看历史合同档案信息
        // openHistoricalContract() {
        //     this.$toast({
        //         title: true,
        //         text: '历史合同档案信息',
        //         type: 'eject',
        //         width: '78%',
        //         height: '93%',
        //         t_url: 'salesBusiness/contractArchives/index',
        //         viewPosition: 'view',
        //         extr: {
        //             contract_number: this.old_contract_number,
        //         },
        //     });
        // },
    },
};
</script>
<style lang="stylus">
.contractArchives
    height 100%
    padding .1rem
    font-size .16rem
    overflow hidden
    header
        border 1px solid #D7D7D7
        background-image none
        height .45rem
        display flex
        .item
            flex 1
            text-align center
            line-height .45rem
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            padding 0 .1rem
            .circle
                width .26rem
                height .26rem
                display inline-block
                text-align center
                line-height .25rem
                color #fff
                background #5588F1
                border-radius 50%
                margin-right .05rem
                .iconfont
                    font-size .16rem
            .contractNo
                cursor pointer
            .el-button
                margin-left 0.3rem
                padding 0
                font-size 0.16rem
                line-height 0.31rem
                padding 0 0.15rem
                vertical-align top
                margin-top 0.05rem
        .item-border
            position relative
            &:after
                content ''
                position absolute
                right 0
                height .16rem
                top .15rem
                border-right 1px solid #D7D7D7
    .pay-mode
        border 1px solid #D7D7D7
        border-bottom 0
        margin-top .1rem
        overflow hidden
        .left
            float left
            width .4rem
            height 1.72rem
            background:linear-gradient(317deg,rgba(85,167,254,1) 0%,rgba(62,121,197,1) 100%);
            font-size .16rem
            color #fff
            text-align center
            padding-top .5rem
        .right
            margin-left .4rem
            .top
                height: 0.45rem;
                line-height 0.45rem;
                padding-left .1rem
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            .bottom
                box-sizing border-box
                overflow hidden
                background #f8fdff
                border 1px solid #ccebf7
                border-left 0;
                .type-name
                    width 0.25rem;
                    height: 1.236rem;
                    line-height 0.2rem
                    text-align center
                    padding 0.1rem 0.05rem
                    background #e9f3f7
                    float left
                    color #2f7591
                .detail
                    width: calc(100% - 0.25rem);
                    height: 1.236rem;
                    padding: 0.114rem;
                    float left
                    .bottom-item
                        height .2rem
                        font-size .14rem
                        margin .04rem 0
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        .pre
                            padding 0 .1rem
                            background #2aaadd
                            position relative
                            color #fff
                            &:after
                                content ''
                                width 0
                                height 0
                                position absolute
                                right -10px
                                top .05rem
                                border-width 5px
                                border-style solid
                                border-color transparent #2aaadd transparent transparent
                                -webkit-transform rotate(180deg)
                                transform rotate(180deg)
                        .content
                            background rgba(85,136,241,0.08)
                            padding 0 .1rem
                            .item
                                margin-right .05rem
                                .blue
                                    color #2aaadd
                        &.more
                            color #2aaadd
                            cursor pointer
            .mode2
                border 1px solid #dcbe8c !important
                background #fffdfb !important
                .type-name
                    color #80540b !important
                    background #f5f2ed  !important
                .bottom-item
                    &.more
                        color #ecb726 !important
                    .pre
                        background #ecb726 !important
                        &:after
                            border-color transparent #ecb726 transparent transparent !important
                .content
                    .item
                        .blue
                            color #ecb726 !important
    .payed
        border 1px solid #D7D7D7
        height .4rem
        margin-top .1rem
        overflow hidden
        position relative
        .item
            display inline-block
            height 100%
            vertical-align middle
            &.item1
                background url(./../../../assets/images/sale/contract_red.png) no-repeat
                background-size 100% 100%
                width 1.7rem
                padding-left .1rem
                line-height .4rem
                color #fff
                position absolute
                z-index 12
            &.item-bg
                width 1.16rem
                height .4rem
                font-size .12rem
                text-align center
                .num
                    font-size .14rem
                    color #FA8436
                    padding-top .02rem
                    line-height .2rem
                .date
                    font-size .12rem
                    line-height .16rem
            &.item-bg0
                background url(./../../../assets/images/sale/contract_bg.png) no-repeat
                background-size 100% 100%
        .item-con
            position absolute
            left 1.6rem
            top 0
            width calc(100% - 1.6rem)
            height .4rem
            .warpper
                transition 0.3s
                width auto
                position absolute
                left 0
                top 0
                white-space nowrap
            .btn-con
                position absolute
                right 0
                height 100%
                background #fff
                cursor pointer
                display flex
                .custom-btn
                    width .14rem
                    height .39rem
                    background #F0F0F0
                    float left
                    text-align center
                    line-height .39rem
                    flex 1
                    .iconfont
                        color #C6C6C6
                        font-size .16rem
                    &:hover
                        background #E7E7E7
                        .iconfont
                            color #000000
                .left-btn
                    margin-right 1px
                    line-height 0.38rem
                    transform rotate(-180deg)
    .refund-records
        .item
            &.item1
                background url(./../../../assets/images/sale/green2.png) no-repeat
            &.item-bg0
                background url(./../../../assets/images/sale/bg2.png) no-repeat
                background-size 100% 100%
            &.item-bg
                .num
                    color #01b59b
    .deliveries-case
        margin-top .1rem
        .hearder
            overflow hidden
            border 1px solid #D7D7D7
            border-bottom none
            .tit
                float left
                background url(./../../../assets/images/sale/contract_green.png) no-repeat
                width 1.7rem
                height .4rem
                padding-left .1rem
                line-height .4rem
                color #fff
            .describe
                float left
                line-height .4rem
                padding-left .1rem
                font-size .14rem
        .custom-table
            border none
            border-spacing 0px
            border-collapse collapse
            width 100%
            td,th
                border 1px solid #D7D7D7
                border-collapse collapse
                height .42rem
                line-height .42rem
                font-size .16rem;
                padding-left .1rem

            .td-tit
                background rgba(53,166,184,0.06)
                width 1.5rem
    .date-picker
        margin .1rem 0
        .export_btn
            float right
        .search-input
            display inline-block
            width 2rem
            margin-left .2rem
        .sum-span
            margin-left .3rem
    .table-bg
        height calc(100% - 5.3rem)
        .el-table
            height calc(100% - .4rem)
            .green
                color #00839B
            .blue
                color #014ECA
            .purple
                color #772CC3
            .account
                color #022782
            .total
                color #C9661E
        .el-pagination
            text-align right
            margin-top .08rem
</style>
